import { Component, OnInit } from '@angular/core';
import { Device } from '../models/Device';
import { DevicesService } from '../services/devices.service';
import { LanguageService } from '../services/language.service';
import translation from "../translation/devicelist.json";

@Component({
  selector: 'app-devicelist',
  templateUrl: './devicelist.component.html',
  styleUrls: ['./devicelist.component.css']
})
export class DevicelistComponent implements OnInit {
  public langStrings;
  public devices: Device[] = [];

  constructor(public langService: LanguageService, private devicesService: DevicesService) { }

  ngOnInit(): void {
    this.langStrings = translation[this.langService.getLang()];
    this.devicesService.getDevices().subscribe(device => {
      this.devices = device;
      });
  }

}
