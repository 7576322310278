export class Visit {
    private id: number;
    private date: Date;
    private presetID: number;
    private examinationIDs: number[];

    constructor(id: number, date: Date, presetID?: number, examinationIDs?: number[]) {
        this.id = id;
        this.date = date;

        if (presetID != undefined) {
            this.presetID = presetID;
        } 

        if (examinationIDs != undefined) {
            this.examinationIDs = examinationIDs;
        } else {
            this.examinationIDs = []
        }
    }

    public getID(): number {
        return this.id;
    }

    public getDate(): Date {
        return this.date;
    }

    public getExaminationIDs(): number[] {
        return this.examinationIDs;
    }

    public getPresetID(): number {
        return this.presetID;
    }

    public setPresetID(presetID: number) {
        this.presetID = presetID;
    }
}