export class ExaminationPreset{
    private id: number;
    private name_pl_PL: string;
    private name_en_US: string;

    private examinationTypes: number[];

    constructor(id: number, name_pl_PL: string, name_en_US: string, examinationTypes: number[]){
        this.id = id;
        this.name_pl_PL = name_pl_PL;
        this.name_en_US = name_en_US;
        this.examinationTypes = examinationTypes;
    }

    getName(language: string): string{
        if(language == "pl-PL"){
            return this.name_pl_PL;
        }
        else if(language == "en-US"){
            return this.name_en_US;
        }
        else{
            return this.name_en_US;
        }
    }

    getID(): number{
        return this.id;
    }

}