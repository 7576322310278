import { SexType } from "./SexType";
import { SocialIDType } from "./SocialIDType";

export class Patient {

    private id: number;
    private firstName: string;
    private lastName: string;
    private heightCM: number;
    private weightKG: number;
    private ankleHeight: number;
    private leftCalfLength: number;
    private rightCalfLength: number;
    private birthDate: Date;
    private sex: SexType;
    private socialIDType: SocialIDType;
    private socialID: string;

    private visitIDs: string[];

    private doctorNotes: string;

    constructor(id: number, firstName: string, lastName: string, heightCM: number, weightKG: number, ankleHeight: number, leftCalfLength: number, rightCalfLength: number, birthDate: Date, sex: SexType, socialIDType: SocialIDType, socialID: string, doctorNotes?: string) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.heightCM =  heightCM;
        this.weightKG = weightKG;
        this.ankleHeight = ankleHeight;
        this.leftCalfLength = leftCalfLength;
        this.rightCalfLength = rightCalfLength;
        this.birthDate = birthDate;
        this.sex = sex;
        this.socialIDType = socialIDType;
        this.socialID = socialID;
        
        if (doctorNotes != undefined) {
            this.doctorNotes = doctorNotes;
        } else {
            this.doctorNotes = "";
        }
    }

    public getID(): number {
        return this.id;
    }

    getFirstName(): string {
        return this.firstName;
    }

    getLastName(): string {
        return this.lastName;
    }

    getSocialIDType(): SocialIDType {
        return this.socialIDType;
    }

    getSocialID(): string {
        return this.socialID;
    }

    getBirthDate(): Date {
        return this.birthDate;
    }

    getWeight(): number {
        return this.weightKG;
    }

    getHeight(): number {
        return this.heightCM;
    }

    getSex(): SexType {
        return this.sex;
    }

    getAnkleHeight(): number {
        return this.ankleHeight;
    }

    getLeftCalfLength(): number {
        return this.leftCalfLength;
    }

    getRightCalfLength(): number {
        return this.rightCalfLength;
    }
}