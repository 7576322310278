import { Component, OnInit } from '@angular/core';
import { Patient } from '../models/Patient';
import { Visit } from '../models/Visit';
import { LanguageService } from '../services/language.service';
import { PatientsService } from "../services/patients.service";
import { VisitsService } from '../services/visits.service';
import translation from '../translation/patientlist.json'

// TODO
// Patient selection

@Component({
  selector: 'app-patientlist',
  templateUrl: './patientlist.component.html',
  styleUrls: ['./patientlist.component.css']
})
export class PatientlistComponent implements OnInit {
  patients: Patient[] = [];
  visits: Visit[] = [];
  public langStrings;
  selectedPatient: Patient;
  public validSelection = false;
  private selectedVisit: Visit;

  constructor(
    private visitsService: VisitsService,
    private patientsService: PatientsService,
    private langService: LanguageService) {
    
   }

  ngOnInit(): void {
    this.patientsService.getPatients().subscribe(patients => {
      this.patients = patients;
      if (this.patients.length > 0) {
        this.selectPatient(1);
      }
    });
    
    this.langStrings = translation[this.langService.getLang()];
    // console.log(this.selectedPatient);
  }

  selectPatient(id: number): void {
    this.selectedPatient = this.patients.find(patient => patient.getID() == id);
    this.validSelection = true;
    this.visitsService.getVisits(this.selectedPatient.getID()).subscribe(visits => {
      this.visits = visits;
    });
  }

  selectVisit(id: number): void {
    this.selectedVisit = this.visits.find(visit => visit.getID() === id);
  }

}
