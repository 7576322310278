<div class="p-3 rounded-3">

<div class="row d-flex justify-content-center">
  <div class="col-2"></div>
    <div class="col-8 mb-3 d-flex justify-content-center">
        <form class="col-8 me-2">
            <!-- <input type="search" class="form-control" placeholder="Search..."> -->
            <select class="form-select is-invalid" [ngClass]="{'is-valid': validSelection, 'is-invalid': !validSelection}" required="" (change)="selectPreset($event.target.value)">
                <!-- <option *ngFor="let patient of patients" value="{{patient.getID()}}">
                    {{patient.getFirstName()}} {{patient.getLastName()}} - {{patient.getBirthDate().toLocaleDateString(langService.getLang())}}
                </option> -->
                <option *ngIf="!validSelection" enabled="false" selected="true" disabled>{{langStrings['choose']}}</option>
                <option *ngFor="let preset of examinationPresets" [selected]="preset.getID() === seletedPresetID" value="{{preset.getID()}}">{{preset.getName(langStrings["lang"])}}</option>
            </select>
        </form>
    </div>
  <div class="col-2 text-end">
    <button class="btn btn-info btn-small"><i class="bi bi-printer-fill text-white"></i></button>
  </div>
</div>



<div class="accordion mb-2" id="accordionFlushExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingOne">
        <button class="accordion-button collapsed d-flex justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            <div class="col me-3">
              Calibration
            </div>
            <div class="col me-3 text-end">
              <span class="badge bg-warning">{{langStrings['not-performed']}}</span>
            </div>
        </button>
      </h2>
      <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne">
        <div class="accordion-body">
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius et purus id imperdiet. In dictum arcu consectetur mauris lobortis, mollis iaculis nisi sollicitudin. Nunc pretium placerat varius. Aenean gravida, augue quis vehicula commodo, purus neque pellentesque metus, nec sagittis magna nunc a nisl.
          </div>
          <div class="text-end mt-3 d-flex justify-content-end">
            <div class="col-md-4 my-0">
              <div class="input-group mb-3">
                <span class="input-group-text" id="inputGroup-sizing-default"><i class="bi bi-hourglass-split"></i></span>
                <input type="text" class="form-control" aria-label="Sizing example input" value="15" aria-describedby="inputGroup-sizing-default">
                <button class="btn btn-outline-secondary" type="button" id="button-addon2">-</button>
                <button class="btn btn-outline-secondary" type="button" id="button-addon2">+</button>
              </div>
            </div>
            <div><button type="button" class="btn btn-primary ms-2">{{langStrings['perform']}}</button></div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="flush-headingTwo">
        <button class="accordion-button collapsed d-flex justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="true" aria-controls="flush-collapseTwo">
            <div class="col me-3">
              Gait with head movements in transverse plane
            </div>
            <div class="col me-3 text-end">
              <span class="badge bg-success">14.11.2021 9:33</span>
            </div>
        </button>
      </h2>
      <div id="flush-collapseTwo" class="accordion-collapse collapse show" aria-labelledby="flush-headingTwo">
        <div class="accordion-body">
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent varius et purus id imperdiet. In dictum arcu consectetur mauris lobortis, mollis iaculis nisi sollicitudin. Nunc pretium placerat varius. Aenean gravida, augue quis vehicula commodo, purus neque pellentesque metus, nec sagittis magna nunc a nisl.
          </div>
          <div class="text-end mt-3 d-flex justify-content-end">
            <div class="col-md-4 my-0">
              <div class="input-group mb-3">
                <span class="input-group-text" id="inputGroup-sizing-default"><i class="bi bi-hourglass-split"></i></span>
                <input type="text" class="form-control" aria-label="Sizing example input" value="15" aria-describedby="inputGroup-sizing-default">
                <button class="btn btn-outline-secondary" type="button" id="button-addon2">-</button>
                <button class="btn btn-outline-secondary" type="button" id="button-addon2">+</button>
              </div>
            </div>
            <div><button type="button" class="btn btn-primary ms-2">{{langStrings['perform']}}</button></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center mb-2"><button type="button" class="btn btn-secondary">{{langStrings['add-examination']}}</button></div>

  <div>
    <label for="validationServer01" class="form-label ms-1">{{langStrings['notes']}}:</label>
    <textarea id="description" class="form-control" placeholder="Please describe everything, don't rush" rows="5"></textarea>
  </div>
    
</div>