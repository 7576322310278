<div class="row d-flex justify-content-between">
    <div class="col my-auto">
        <h4 class="mb-0">
            {{patient.getFirstName()}} {{patient.getLastName()}}
        </h4>
    </div>
    <div class="col-3">
        <img class="rounded-circle float-end me-4" src="assets/default-avatar.png" height="60px">
    </div>
</div>
<div class="row d-flex justify-content-between">
    <div class="col">
        <span>{{IDTypeAsString}}: {{patient.getSocialID()}}</span><br>
        <span class="text-secondary">{{langStrings['birthday']}}: {{patient.getBirthDate().toLocaleDateString(langService.getLang())}} (<strong>{{age}}</strong>)</span><br>
        <span class="text-secondary">{{langStrings['sex']}}: {{displayedSex}}</span><br>
            
        <span class="text-secondary">{{langStrings['height']}}: {{patient.getHeight()}} cm, {{langStrings['weight']}}: {{patient.getWeight()}} kg</span><br>
        <span class="text-secondary">{{langStrings['leftCalfLength']}}: <strong>{{patient.getLeftCalfLength()}} cm</strong></span><br>
        <span class="text-secondary">{{langStrings['rightCalfLength']}}: <strong>{{patient.getRightCalfLength()}} cm</strong></span><br>
        <span class="text-secondary">{{langStrings['ankleHeight']}}: <strong>{{patient.getAnkleHeight()}} cm</strong></span><br>
    </div>
</div>
<span class="float-end me-4"><button class="btn btn-sm mx-1 btn-info text-white">{{langStrings['button-edit']}}</button></span>