<div class="row d-flex justify-content-center">
    <div class="col-12 col-lg-4 mb-3 d-flex justify-content-center">
        <form class="col-8 me-2">
            <!-- <input type="search" class="form-control" placeholder="Search..."> -->
            <select class="form-select is-invalid" [ngClass]="{'is-valid': validSelection, 'is-invalid': !validSelection}" id="validationServer04" required="" (change)="selectPatient($event.target.value)">
                <option *ngFor="let patient of patients" value="{{patient.getID()}}">
                    {{patient.getFirstName()}} {{patient.getLastName()}} - {{patient.getBirthDate().toLocaleDateString(langService.getLang())}}
                </option>
            </select>
        </form>
        <button class="col-2 col-xl-1 btn btn-success p-0" data-bs-toggle="modal" data-bs-target="#addPatient">
            <i class="bi bi-plus-lg" style="font-size: 0.9rem;"></i>
        </button>
    </div>
</div>

<!-- <div class="row row-cols-1 row-cols-xl-3" *ngIf="validSelection"> -->
<div *ngIf="selectedPatient != null" class="row row-cols-1 row-cols-xl-3">
    <div class="col col-xl-3">
        <div class="card mb-3 rounded-5 shadow-sm">
            <div class="card-header text-center py-1 bg-primary text-white">{{langStrings['listOfTests']}}</div>
            <div class="card-body pt-2">
                <div class="text-center d-grid gap-2 pb-2"><button  type="button" class="btn btn-sm btn-success">{{langStrings['newVisit']}}</button></div>
                <app-visitlist [visits]="visits" (selectedVisitEmitter)="selectVisit($event)"></app-visitlist>
            </div>
        </div>
    </div>
    <div class="col col-xl-6">
        <div class="card mb-3 rounded-5 shadow-sm">
            <div class="card-header text-center py-1 bg-primary text-white">{{langStrings['testDetails']}}</div>
            <app-examinationdetails [visit]="selectedVisit"></app-examinationdetails>
        </div>
    </div>
    <div class="col col-xl-3">
        <div class="card mb-3 rounded-5 shadow-sm">
            <div class="card-header text-center py-1 bg-primary text-white">
                {{langStrings['patient']}}
            </div>
            <div class="card-body"><app-patientcard [patient]="selectedPatient"></app-patientcard></div>
        </div>
    </div>
</div>

<!-- Add Patient Modal -->
<app-add-patient-modal></app-add-patient-modal>