import { ConnectionStatusType } from "./ConnectionStatusType";
import { PositionType } from "./PositionType";

export class Device {
    private id: number;
    private macAddress: string;
    private name: string;
    private position: PositionType;
    private connectionStatus: ConnectionStatusType;
    private batteryLevel: number;

    constructor(id: number, macAddress: string, name: string, position: PositionType, connectionStatus: ConnectionStatusType, batteryLevel: number) {
        this.id = id;
        this.macAddress = macAddress;
        this.name = name;
        this.position = position;
        this.connectionStatus = connectionStatus;
        this.batteryLevel = batteryLevel;
    }

    getID(): number {
        return this.id;
    }

    getMacAddress(): string {
        return this.macAddress;
    }
    
    getName(): string {
        return this.name;
    }

    getPosition(): PositionType {
        return this.position;
    }

    getConnectionStatus(): ConnectionStatusType {
        return this.connectionStatus;
    }

    getBatteryLevel(): number {
        return this.batteryLevel;
    }
}