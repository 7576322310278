import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { ConnectionStatusType } from '../models/ConnectionStatusType';
import { Device } from '../models/Device';
import { PositionType } from '../models/PositionType';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {
  private devices: Device[] = [];

  constructor() { 
    this.loadDevices();
  }

  loadDevices(): void {
    let devicesJSON = JSON.parse(localStorage.getItem('devices'));

    if (devicesJSON != undefined) {
      for (let device of devicesJSON) {
        this.devices.push(new Device(
          device.id,
          device.macAddress,
          device.name,
          device.position,
          device.connectionStatus,
          device.batteryLevel
        ));
      }
    }
  }

  saveDevices(): void {
    localStorage.setItem('devices', JSON.stringify(this.devices));
  }

  deleteAllDevices(): void {
    localStorage.removeItem('devices');
  }

  createDevice(macAddress: string, name: string, position: PositionType, connectionStatus: ConnectionStatusType, batteryLevel: number): void {
    this.devices.push(new Device(this.findNextID(), macAddress, name, position, connectionStatus, batteryLevel));
    this.saveDevices();
  }

  getDevices(): Observable<Device[]> {
    return of(this.devices);
  }

  findNextID(): number {
    let maxID = 0;

    for (let device of this.devices) {
      if (device.getID() > maxID) {
        maxID = device.getID();
      }
    }

    return maxID + 1;
  }

  createDebugDevices(): void {
    this.createDevice('00:00:00:00:00:00', '23', PositionType.Ankle, ConnectionStatusType.CONNECTED, 82);
    this.createDevice('00:00:00:00:00:01', '26', PositionType.CalfLeft, ConnectionStatusType.DISCONNECTED, 51);
    this.createDevice('00:00:00:00:00:02', '48', PositionType.CalfRight, ConnectionStatusType.CONNECTING, 0);
    this.createDevice('00:00:00:00:00:03', '12', PositionType.Foot, ConnectionStatusType.CONNECTED, 100);
    this.createDevice('00:00:00:00:00:04', '34', PositionType.Back, ConnectionStatusType.CONNECTED, 23);
  }

}
