export enum PositionType {
    None = 0,
    Ankle = 1,
    Elbow = 2,
    Foot = 3,
    Hand = 4,
    Hip = 5,
    Knee = 6,
    Neck = 7,
    ShoulderLeft = 8,
    WristLeft = 9,
    WristRight = 10,
    Waist = 11,
    Back = 12,
    Chest = 13,
    Head = 14,
    ShoulderRight = 15,
    CalfLeft = 16,
    CalfRight = 17,
}