import { Component, Input, OnInit } from '@angular/core';
import { Patient } from '../models/Patient';
import { SexType } from '../models/SexType';
import { SocialIDType } from '../models/SocialIDType';
import { LanguageService } from '../services/language.service';
import translation from '../translation/patientcard.json'

// Editable
// Verify PESEL when editing (just warning, allow override, always display yellow warning)

@Component({
  selector: 'app-patientcard',
  templateUrl: './patientcard.component.html',
  styleUrls: ['./patientcard.component.css']
})
export class PatientcardComponent implements OnInit {
  @Input() public patient: Patient;
  public langStrings;
  public displayedSex;
  public IDTypeAsString: string;
  public age: string = "";
  
  constructor(public langService: LanguageService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.langStrings = translation[this.langService.getLang()];
    this.updateSex();
    this.updateIDType();
    this.updateAge();
  }

  updateSex() {
    let patientSexString = SexType[this.patient.getSex()];
    this.displayedSex = this.langStrings[patientSexString];
  }

  updateIDType() {
    this.IDTypeAsString = SocialIDType[this.patient.getSocialIDType()];
  }

  updateAge() {
    let today = new Date();
    let age = today.getFullYear() - this.patient.getBirthDate().getFullYear();
    let m = today.getMonth() - this.patient.getBirthDate().getMonth();
    if (m < 0 || (m === 0 && today.getDate() < this.patient.getBirthDate().getDate())) {
      age--;
    }
    this.age = age.toString();
  }

}
