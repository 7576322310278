import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatientlistComponent } from './patientlist/patientlist.component';
import { DevicelistComponent} from './devicelist/devicelist.component'

const routes: Routes = [
  {path: '', component: PatientlistComponent},
  {path: 'patients', component: PatientlistComponent},
  {path: 'devices', component: DevicelistComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
