import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { Patient } from "../models/Patient";
import { SexType } from "../models/SexType";
import { SocialIDType } from "../models/SocialIDType";

// TODO
// Get patient by ID
// Modify patient
// Remove patient
// API

@Injectable({
  providedIn: 'root'
})
export class PatientsService {
  patients: Patient[] = [];
  host: string = "https://localhost:44345/"
  patientsURL: string = "PatientData/All"

  constructor(private http: HttpClient) { 
    this.loadPatients();
  }

  getPatients(): Observable<Patient[]> {
    return of(this.patients);
  }

  loadPatients(): void {
    let patientsJSON = JSON.parse(localStorage.getItem('patients'));

    if (patientsJSON != undefined) {
      for (let patient of patientsJSON) {
        this.patients.push(new Patient (
          patient.id,
          patient.firstName,
          patient.lastName,
          patient.heightCM,
          patient.weightKG,
          patient.ankleHeight,
          patient.leftCalfLength,
          patient.rightCalfLength,
          new Date(patient.birthDate),
          patient.sex,
          patient.socialIDType,
          patient.socialID,
          patient.doctorNotes
        ))
      }
    }
  }

  loadPatientsAPI(): Observable<Patient[]> {
    let patients = of(this.patients);
    
    this.http.get<string>(this.host+this.patientsURL).subscribe(patients => {
      let patientsJSON = JSON.parse(patients);
    });

    return patients;
  }
  
  savePatients(): void {
    localStorage.setItem('patients', JSON.stringify(this.patients));
  }

  deleteAllPatients(): void {
    localStorage.removeItem('patients');
  }

  createPatient(firstName: string, lastName: string, heightCM: number, weightKG: number, ankleHeight: number, leftCalfLength: number, rightCalfLength: number, birthDate: Date, sex: SexType, socialIDType: SocialIDType, socialID: string) {
    this.patients.push(new Patient(this.findNextID(), firstName, lastName, heightCM, weightKG, ankleHeight, leftCalfLength, rightCalfLength, birthDate, sex, socialIDType, socialID))
    this.savePatients();
  }

  findNextID(): number {
    let maxID = 0;
    for (let patient of this.patients) {
      if (patient.getID() > maxID) {
        maxID = patient.getID();
      }
    }

    return maxID + 1;
  }

  createDebugPatients(): void {
    this.createPatient("Serafina", "Dąbrowski", 151, 66, 6, 31, 31, new Date(1991, 4, 6), SexType.Female, SocialIDType['Paszport Polsatu'], "91040676827");
    this.createPatient("Aleksander", "Piotrowski", 169, 87, 7, 33, 32, new Date(1958, 5, 19), SexType.Male, SocialIDType.PESEL, "58051947571");
  }
}
