<div class="row">
    <div *ngFor="let device of devices" class="col-xl-3">
        <div class="card mb-3 rounded-5 shadow-sm">
            <div [ngClass]="
            {'bg-secondary': device.getConnectionStatus() == 0 || device.getConnectionStatus() == 1 , 'bg-primary': device.getConnectionStatus() == 2}" 
            class="card-header text-center py-1 text-white d-flex justify-content-between">
            <!-- <div class="card-header text-center py-1 text-white bg-primary d-flex justify-content-between"> -->
                {{langStrings['device']}} {{device.id}}
            </div>
            <div class="card-body">
                <app-devicecard [device]="device"></app-devicecard>
            </div>
        </div>
    </div>
</div>