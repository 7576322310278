import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { SocialIDType } from "../models/SocialIDType";
import translation from '../translation/add-patient-modal.json'
import { transformAll } from '@angular/compiler/src/render3/r3_ast';
import { SexType } from '../models/SexType';
import { ValidationType } from '../models/ValidationType';
import { PatientsService } from '../services/patients.service';

// TODO Validation
// TODO Autofill based on PESEL
// TODO Uncrap socialIDTypeValidation and sexValidation
@Component({
  selector: 'app-add-patient-modal',
  templateUrl: './add-patient-modal.component.html',
  styleUrls: ['./add-patient-modal.component.css']
})
export class AddPatientModalComponent implements OnInit {
  public langStrings;
  public socialIDTypes = this.getHalfEnum(SocialIDType);
  public sexTypes = this.getHalfEnum(SexType);
  public validationType = ValidationType;

  public firstName: string = "";
  public lastName: string = "";
  public socialIDType: string = "";
  public socialID: string = "";
  public birthday: string = "";
  public birthdayButDate: Date;
  public sex: string = "";
  public height: string = "";
  public weight: string = "";
  public leftCalfLength: string = "";
  public rightCalfLength: string = "";
  public ankleHeight: string = "";

  public selectedDate = new Date("1970");
  public age: string = "";

  public isFirstNameValid: ValidationType = ValidationType.empty;
  public isLastNameValid: ValidationType = ValidationType.empty;
  public isSocialIDTypeValid: ValidationType = ValidationType.empty;
  public isSocialIDValid: ValidationType = ValidationType.empty;
  public isBirthdayValid: ValidationType = ValidationType.valid;
  public isSexValid: ValidationType = ValidationType.empty;
  public isHeightValid: ValidationType = ValidationType.empty;
  public isWeightValid: ValidationType = ValidationType.empty;
  public isLeftCalfLengthValid: ValidationType = ValidationType.empty;
  public isRightCalfLengthValid: ValidationType = ValidationType.empty;
  public isAnkleHeightValid: ValidationType = ValidationType.empty;

  constructor(public langService: LanguageService, private patientsService: PatientsService) { }

  ngOnInit(): void {
    this.langStrings = translation[this.langService.getLang()];
  }

  getHalfEnum(myEnum: Object): string[] {
    let halfLen = Math.ceil(Object.keys(myEnum).length / 2);
    return Object.keys(myEnum).slice(halfLen);
  }

  firstNameValidation(): boolean {
    if (this.firstName.length > 0) {
      this.isFirstNameValid = ValidationType.valid;
      return true;
    } else {
      this.isFirstNameValid = ValidationType.invalid;
      return false;
    }
  }

  lastNameValidation(): boolean {
    if (this.lastName.length > 0) {
      this.isLastNameValid = ValidationType.valid;
      return true;
    } else {
      this.isLastNameValid = ValidationType.invalid;
      return false;
    }
  }

  socialIDTypeValidation(typeStr?: string): boolean {
    if (typeStr) {
      if (typeStr in SocialIDType) {
        this.isSocialIDTypeValid = ValidationType.valid;
        this.socialIDType = typeStr;
        return true;
      } else {
        this.isSocialIDTypeValid = ValidationType.invalid;
        return false;
      }
    } else {
      if (this.socialIDType in SocialIDType) {
        this.isSocialIDTypeValid = ValidationType.valid;
        return true;
      }
    }
    this.isSocialIDTypeValid = ValidationType.invalid;
    return false;
  }

  socialIDValidation(): boolean {
    if (this.socialID.length > 0) {
      this.isSocialIDValid = ValidationType.valid;
      return true;
    } else {
      this.isSocialIDValid = ValidationType.invalid;
      return false;
    }
  }
  
  sexValidation(typeStr ?: string): boolean {
    if (typeStr) {
      if (typeStr in SexType) {
        this.isSexValid = ValidationType.valid;
        this.sex = typeStr;
        return true;
      } else {
        this.isSexValid = ValidationType.invalid;
        return false;
      }
    } else {
      if (this.sex in SexType) {
        this.isSexValid = ValidationType.valid;
        return true;
      } 
    }
    this.isSexValid = ValidationType.invalid;
    return false;
  }

  heightValidation(): boolean {
    let num = parseFloat(this.height);
    if (num > 0) {
      this.isHeightValid = ValidationType.valid;
      return true;
    } else {
      this.isHeightValid = ValidationType.invalid;
      return false;
    }
  }

  weightValidation(): boolean {
    let num = parseFloat(this.weight);
    if (num > 0) {
      this.isWeightValid = ValidationType.valid;
      return true;
    } else {
      this.isWeightValid = ValidationType.invalid;
      return false;
    }
  }

  leftCalfLengthValidation(): boolean {
    let num = parseFloat(this.leftCalfLength);
    if (num > 0) {
      this.isLeftCalfLengthValid = ValidationType.valid;
      return true;
    } else {
      this.isLeftCalfLengthValid = ValidationType.invalid;
      return false;
    }
  }

  rightCalfLengthValidation(): boolean {
    let num = parseFloat(this.rightCalfLength);
    if (num > 0) {
      this.isRightCalfLengthValid = ValidationType.valid;
      return true;
    } else {
      this.isRightCalfLengthValid = ValidationType.invalid;
      return false;
    }
  }

  ankleHeightValidation(): boolean {
    let num = parseFloat(this.ankleHeight);
    if (num > 0) {
      this.isAnkleHeightValid = ValidationType.valid;
      return true;
    } else {
      this.isAnkleHeightValid = ValidationType.invalid;
      return false;
    }
  }

  ageUpate(): void {
    document.getElementById("addPatientCalendarButton").click();
    let today = new Date();
    let age = today.getFullYear() - this.selectedDate.getFullYear();
    let m = today.getMonth() - this.selectedDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < this.selectedDate.getDate())) {
      age--;
    }
    this.age = age.toString();
    
    this.birthdayValidation();
  }

  birthdayValidation(): boolean {
    let timeMinus200Years = new Date();
    timeMinus200Years.setFullYear(timeMinus200Years.getFullYear() - 200);
    let timePlus200Years = new Date();
    timePlus200Years.setFullYear(timePlus200Years.getFullYear() + 200);
    if (this.selectedDate < timeMinus200Years || this.selectedDate > timePlus200Years) {
      this.isBirthdayValid = ValidationType.invalid;
      return false;
    } else {
      this.isBirthdayValid = ValidationType.valid;
      return true;
    }
  }

  finalVerification(): boolean {
    // Run all validations
    console.log("Running finalVerification()...")
    let success = true;
    if (!this.firstNameValidation()) {
      success = false;
    }
    if (!this.lastNameValidation()) {
      success = false;
    }
    if (!this.socialIDTypeValidation()) {
      success = false;
    }
    if (!this.socialIDValidation()) {
      success = false;
    }
    if (!this.sexValidation()) {
      success = false;
    }
    if (!this.heightValidation()) {
      success = false;
    }
    if (!this.weightValidation()) {
      success = false;
    }
    if (!this.leftCalfLengthValidation()) {
      success = false;
    }
    if (!this.rightCalfLengthValidation()) {
      success = false;
    }
    if (!this.ankleHeightValidation()) {
      success = false;
    }
    if (!this.birthdayValidation()) {
      success = false;
    }
    console.log("finalVerification(): " + success);
    return success;
  }

  addPatient(): void {
    if (this.finalVerification()) {
      this.patientsService.createPatient(
        this.firstName,
        this.lastName,
        parseFloat(this.height),
        parseFloat(this.weight),
        parseFloat(this.ankleHeight),
        parseFloat(this.leftCalfLength),
        parseFloat(this.rightCalfLength),
        this.selectedDate,
        SexType[this.sex],
        SocialIDType[this.socialIDType],
        this.socialID
      );
      document.getElementById("dismissAddPatientModal").click();
    }
  }
}