import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { ExaminationPreset } from '../models/ExaminationPreset';

@Injectable({
  providedIn: 'root'
})
export class ExaminationPresetsService {
  private presets: ExaminationPreset[] = [];

  constructor() { 
    this.presets = [
      new ExaminationPreset(1, "Przykładowe 1", "Example preset 1", [0,1]),
      new ExaminationPreset(2, "Przykładowe 2", "Example preset 2", [0,2,3]),
      new ExaminationPreset(3, "Przykładowe 3", "Example preset 3", [0,1,2,3]),
    ]
  }

  public getExaminationPresets(): Observable<ExaminationPreset[]> {
    return of(this.presets);
  }

}
