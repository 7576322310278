import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Visit } from '../models/Visit';
import { LanguageService } from '../services/language.service';
import { VisitsService } from '../services/visits.service';
import translation from '../translation/visitlist.json'

@Component({
  selector: 'app-visitlist',
  templateUrl: './visitlist.component.html',
  styleUrls: ['./visitlist.component.css']
})
export class VisitlistComponent implements OnInit {
  @Input() visits: Visit[] = [];
  @Output() selectedVisitEmitter = new EventEmitter<number>();
  public selectedVisitID: number;
  public langStrings;

  constructor(private visitsService: VisitsService, private langService: LanguageService) { }

  ngOnInit(): void {
    this.langStrings = translation[this.langService.getLang()];
    this.setActive(this.visits[0].getID());
  }

  setActive(id: number): void {
    this.selectedVisitID = id;
    this.selectedVisitEmitter.emit(id);
  }

}
