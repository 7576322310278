import { TmplAstRecursiveVisitor } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { ExaminationPreset } from '../models/ExaminationPreset';
import { Visit } from '../models/Visit';
import { ExaminationPresetsService } from '../services/examination-presets.service';
import { LanguageService } from '../services/language.service';
import { VisitsService } from '../services/visits.service';
import translation from "../translation/examinationdetails.json";

@Component({
  selector: 'app-examinationdetails',
  templateUrl: './examinationdetails.component.html',
  styleUrls: ['./examinationdetails.component.css']
})
export class ExaminationdetailsComponent implements OnInit {
  @Input() visit: Visit;
  public langStrings;
  public examinationPresets: ExaminationPreset[] = [];
  public seletedPresetID: number;
  public validSelection = false;

  constructor(
    public langService: LanguageService,
    private examinationPresetsService: ExaminationPresetsService,
    private visitsService: VisitsService) { }

  ngOnInit(): void {
    this.langStrings = translation[this.langService.getLang()];
    this.updatePresets();
  }

  selectPreset(id) {
    id = parseInt(id);
    this.seletedPresetID = id;
    this.validSelection = true;
    this.visit.setPresetID(id);
    this.visitsService.updateVisit(this.visit);
    this.updatePresets();
  }

  updatePresets() {
    // Because html
    this.examinationPresets = [];
    setTimeout(() => {
      this.examinationPresetsService.getExaminationPresets().subscribe(presets => {
        this.examinationPresets = presets;
      });
    }, 10);
  }

  ngOnChanges() {
    if (this.visit.getPresetID() != undefined) {
      this.seletedPresetID = this.visit.getPresetID();
      this.validSelection = true;
      this.updatePresets();
    } else {
      this.validSelection = false;
    }
  }

}
