import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor() { }

  getLang() {
    if (this.checkExistingSettings()) {
      return this.getExistingSettings()
    }

    // Set the default as fallback
    return this.setLang("en-US");
  }

  checkExistingSettings(): boolean {
    if (localStorage.getItem("lang") === null) {
      return false;
    }
    return true;
  }

  getExistingSettings(): string {
    return localStorage.getItem("lang");
  }

  setLang(lang: string): string {
    localStorage.setItem("lang", lang);
    return lang;
  }
}
