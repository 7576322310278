<div class="row">
    <div class="col-8">
        <div *ngIf="device.getConnectionStatus() == 1" class="spinner-border spinner-border-sm text-warning mt-1" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <i *ngIf="device.getConnectionStatus() == 2" class="bi bi-check-lg text-success"></i>
        <i *ngIf="device.getConnectionStatus() == 0" class="bi bi-x-lg text-danger"></i>
        <strong class="mb-0"> {{langStrings[connectionStatusTypes[device.getConnectionStatus()]]}}</strong><br>

        <i class="bi bi-signpost"></i>
        <span class="mb-0"> {{langStrings['macAddress']}}: </span><br>

        <i class="bi bi-search"></i>
        <span class="mb-0"> {{langStrings['last_seen']}}: </span><br>

        <i class="bi bi-align-bottom"></i>
        <span class="mb-0"> {{langStrings['position']}}: </span><br>

    </div>
    <div class="col-4">
        <div class="d-flex">
            <img class="mb-1 float-center mx-auto" src="assets/devices/23.png" height="60px">
        </div>
        <div>
            <h5 class="text-center"><strong>#{{device.getName()}}</strong></h5>
        </div>
    </div>
</div>