import { Component, Input, OnInit } from '@angular/core';
import { ConnectionStatusType } from '../models/ConnectionStatusType';
import { Device } from '../models/Device';
import { LanguageService } from '../services/language.service';
import translation from '../translation/devicecard.json'

@Component({
  selector: 'app-devicecard',
  templateUrl: './devicecard.component.html',
  styleUrls: ['./devicecard.component.css']
})
export class DevicecardComponent implements OnInit {
  public langStrings;
  public connectionStatusTypes = ConnectionStatusType;
  @Input() device: Device;

  constructor(public langService: LanguageService) { }

  ngOnInit(): void {
    this.langStrings = translation[this.langService.getLang()];
  }

}
