import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { Visit } from '../models/Visit';

@Injectable({
  providedIn: 'root'
})
export class VisitsService {
  private visits: Visit[] = [];

  constructor() { 
    this.visits.push(
      new Visit(0, new Date("2021-11-03T13:26:48+0200")),
      new Visit(1, new Date("2021-10-02T10:13:48+0200"), 2),
      new Visit(2, new Date("2020-09-01T13:26:48+0200"), 3),
    )
  }

  getVisits(patientID: number): Observable<Visit[]> {
    // return of(this.visits.filter(visit => visit.patientID === patientID));
    return of(this.visits);
  }

  updateVisit(newVisit: Visit) {
    let oldVisit = this.visits.find(v => v.getID() === newVisit.getID());
    let index = this.visits.indexOf(oldVisit);
    this.visits[index] = newVisit;
  }
}
